import type { PageWithLayout } from '@/types/AppWithLayout';
import CaireHead from '@/components/CaireHead';
import useProgramConfig from '@/hooks/useProgramConfig';
import { Center, Loader, Stack } from '@mantine/core';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useCognitoSession } from '@/contexts/Cognito';

const loggedOutHomePath = '/join/start';
const loggedInHomePath = '/journey';

const Home: PageWithLayout = () => {
  const { programName } = useProgramConfig();
  const { authenticated, loading } = useCognitoSession();
  const { push } = useRouter();

  // Not logged in, redirect to welcome page
  useEffect(() => {
    if (!loading && !authenticated) {
      push(loggedOutHomePath);
    }
  }, [authenticated, loading, push]);

  // Logged in, take to dashboard
  useEffect(() => {
    if (authenticated) {
      push(loggedInHomePath);
    }
  }, [authenticated, push]);

  return (
    <>
      <CaireHead title={programName} />
      {/* eslint-disable-next-line react/forbid-elements --
       * Allow <main> element as this standalone page does not use a layout */}
      <main>
        <Center style={{ height: 'calc(50vh)' }}>
          <Stack gap="12px" align="center">
            <Loader size={48} />
            <p>Loading…</p>
          </Stack>
        </Center>
      </main>
    </>
  );
};

export default Home;
